import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */'@/views/LandingView.vue')
  },
  {
    path: '/privacy',
    name: 'privacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  {
    path: '/terms',
    name: 'termsOfUse',
    component: () => import('@/views/TermsOfUse.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/LandingView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
